import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUtensils, faCheckSquare ,faAddressBook } from "@fortawesome/free-solid-svg-icons"

import SEO from "../components/seo"

export default ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="KimGadget について"
      pagedesc="日常をちょっと豊かにするサイト"
      pagepath={location.pathname}
      pageimg={data.about.childImageSharp.original.src}
      pageimgw={data.about.childImageSharp.original.width}
      pageimgh={data.about.childImageSharp.original.height}
    />
    <div className="eye">
      <figure>
        <Img
          fluid={data.about.childImageSharp.fluid}
          alt="eyecatch"
          style={{ height: "100%"}}
        />
      </figure>
    </div>
    <article className="content">
      <div className="container">
        <h1 className="bar">KimGadgetについて</h1>
        <aside className="info">
          <div className="subtitle">
            <FontAwesomeIcon icon={faAddressBook} />
            ABOUT KimGadget
          </div>
        </aside>
        <div className="postbody">
          <p>
            Kimです。地方在住リーマン。
             ガジェット収集が唯一の趣味。
            ただ集めるだけってのもあれなんで、ブログでレビューすることにしました。


          </p>
          <h2>
            <FontAwesomeIcon icon={faCheckSquare} />
            公開している記事
          </h2>
          <p>
            次のような記事を書いて公開しています。
          </p>
          <ul>
            <li>音楽制作を行うことから、オーディオ偏重気味のガジェットレビュー</li>
            <li>働き世代の貴重な可処分時間を捻出する、便利家電レビュー</li>
            <li>毎日を楽しく過ごせるのは健康であってこそ。生活習慣やサプリの試行錯誤ログ
            </li>
          </ul>
        </div>
      </div>
    </article>
  </Layout>
)

export const query = graphql`
  query {
    about: file(relativePath: { eq: "about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
        original {
          src
          height
          width
        }
      }
    }
  }
`
